import {getRequest} from "@pazion/pazion-core/src/services/auth-service";

export async function getAllPackageWithSystemApi() {
    return (await getRequest('all-packages')).data;
}

export async function getSettings(module?: string) {
    let url = "settings";
    if (module){
        url += '/' + module;
    }
    return (await getRequest(url)).data;
}