import {addRoutes, addChildRoute, addRoute, redirectRouteToBackend, removeRouteByName} from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import {getCatalogRoutes} from "@pazion/pazion-catalog/src/module-config";
import Settings from "./views/Settings.vue";
import OrderListDisplayWrapper from "@pazion/pazion-catalog/src/views/orders/OrderListDisplayWrapper.vue";


addRoutes(getCatalogRoutes());

/* customize... */

redirectRouteToBackend('products', true);
redirectRouteToBackend('orders', true);

addRoute(  {
  path: '/stocks',
  name: 'stocks',
  component: OrderListDisplayWrapper,
  meta: {
    'show-in-nav': true,
      "redirect-to-backend": true,
    roles: ['user'],
    sort: -60
  },
});

addRoute(  {
    path: '/orderlisting',
    name: 'orderlisting',
    component: OrderListDisplayWrapper,
    meta: {
        'show-in-nav': true,
        roles: ['user'],
        sort: -60
    },
});





addRoute(  {
    path: '/batch-files',
    name: 'batch files',
    component: Settings,
    meta: {
        'show-in-nav': true,
        "redirect-to-backend": true,
        roles: ['user'],
        sort: -60
    },
});

addRoute(  {
    path: '/fulfilment/transport-list',
    name: 'GLS transportlijst',
    component: Settings,
    meta: {
        'show-in-nav': true,
        "redirect-to-backend": true,
        roles: ['user'],
        sort: -60
    },
});

addRoute(  {
    path: '/batch-files',
    name: 'batch files',
    component: Settings,
    meta: {
        'show-in-nav': true,
        "redirect-to-backend": true,
        roles: ['user'],
        sort: -60
    },
});

/*
addChildRoute("settings",    {
        path: "/api-package-settings",
        name: "API-packages",
        component: Settings,
        meta: {
            "show-in-nav": true,
            roles: ["user"],
            sort: 800
        }
    }
);*/

addChildRoute("settings", {
	path: "/boldotcom/settings",
	name: "boldotcom",
	component: Settings,
	meta: {
		"show-in-nav": true,
		"redirect-to-backend": true,
		roles: ["user"]
	}
});

addChildRoute("settings", {
    path: "/pricegroup",
    name: "pricegroup",
    component: Settings,
    meta: {
        "show-in-nav": true,
        "redirect-to-backend": true,
        roles: ["user"]
    }
});

addChildRoute("settings", {
    path: "/products/import-group",
    name: "import product group",
    component: Settings,
    meta: {
        "show-in-nav": true,
        "redirect-to-backend": true,
        roles: ["user"]
    }
});

removeRouteByName("returns");