
import Vue from 'vue';

import SettingsEntry from './SettingsEntry.vue';
import {Component} from 'vue-property-decorator';
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import {getSettings, getAllPackageWithSystemApi} from "../local_services";

@Component({
  components: {
    SettingsEntry,
    PazLoader
  }
})
export default class Settings extends Vue {

  public settings: object[] = [];
  public isWelcomePage: boolean = true;
  public isLoading: boolean = false;
  public dropDownList: string[] = [];
  public firstSelect: string = 'welcome';

  public created() {
    this.fillDropdown();
  };

  private async fillDropdown(){
    let data = await getAllPackageWithSystemApi();
    this.dropDownList = Object.keys(data['allpackages']);
  };

  private async fetchPackage(packageModule) {
    this.isLoading = true;
    this.isWelcomePage = true;

    if(packageModule != 'welcome'){
      try {
        let data = await getSettings(packageModule);
        this.settings = data['settings'];
        this.isWelcomePage = false;
      } catch (error) {
        console.error(error);
      }
    }
    this.isLoading = false;
  };

  public changedPackage(event){
    this.fetchPackage(event);
  };
}
